import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import RecommendedFilesTab from "./RecommendedFilesTab";
import NSF_Tab from "./NSF_Tab";
import NIH_Tab from "./NIH_Tab";

export default function AddRecommendedFiles({ projectId, requiredFiles }) {
  return (
    <div className="flex justify-end">
      <Dialog>
        <DialogTrigger>
          <span className="btn-fuchsia text-base hover:opacity-80 hover:cursor-pointer">
            See recommended files
          </span>
        </DialogTrigger>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>Add recommended files</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <Tabs defaultValue="recommendations">
            <TabsList>
              <TabsTrigger value="recommendations">Recommendations</TabsTrigger>
              <TabsTrigger value="NSF">NSF</TabsTrigger>
              <TabsTrigger value="NIH">NIH</TabsTrigger>
            </TabsList>
            <TabsContent value="recommendations">
              <RecommendedFilesTab
                requiredFiles={requiredFiles}
                projectId={projectId}
              />
            </TabsContent>
            <TabsContent value="NSF">
              <NSF_Tab projectId={projectId} />
            </TabsContent>
            <TabsContent value="NIH">
              <NIH_Tab projectId={projectId} />
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </div>
  );
}
