import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Textarea } from "@/components/ui/textarea";
import {
  edit_heading_outline,
  regenerate_outline_for_headings,
} from "api/write_space";
import { LoadingSpinner } from "components";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useState } from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function RegenerateOutlineModal({
  projectId,
  templateId,
  heading_id,
  headings,
  headingLabels,
  children,
}) {
  const [promptToUpgrade, setPromptToUpgrade] = useState(false);

  const [selectedHeadingIds, setSelectedHeadingIds] = useState([heading_id]);
  const [feedback, setFeedback] = useState("");
  const [regeneratedOutline, setRegeneratedOutline] = useState(null);
  const [index, setIndex] = useState(0);
  const { trigger: regenerateOutline, isMutating: isRegeneratingOutline } =
    useSWRMutation(
      `regenerate_outline/${templateId}`,
      () =>
        regenerate_outline_for_headings({
          template_id: templateId,
          heading_ids: selectedHeadingIds,
          feedback: feedback,
        }),
      {
        onSuccess: (d) => setRegeneratedOutline(d),
        onError: (err) => {
          if (err.status === 422) {
            setPromptToUpgrade(true);
          }
        },
        throwOnError: false,
      }
    );

  const { trigger: acceptChange } = useSWRMutation(
    "accept_change",
    async (key, { arg: { heading_id, outline } }) => {
      await edit_heading_outline({
        template_id: templateId,
        heading_id: heading_id,
        outline: outline,
      });
      return outline;
    },
    {
      onSuccess: (d) => mutate(`/write/template/${projectId}/${templateId}`),
    }
  );

  const handleOnCheck = (isAdding, heading_id) => {
    if (isAdding) {
      setSelectedHeadingIds((headingIds) => [...headingIds, heading_id]);
    } else {
      setSelectedHeadingIds((headingIds) =>
        headingIds.filter((headingId) => headingId !== heading_id)
      );
    }
  };

  const handleAcceptChange = ({ heading_id, outline }) => {
    setIndex((i) => (i === 0 ? 0 : i - 1));
    acceptChange({ heading_id, outline });
    setRegeneratedOutline((d) =>
      d.filter((heading) => heading.heading_id !== heading_id)
    );
  };
  const handleDenyChange = (heading_id) => {
    setIndex((i) => (i === 0 ? 0 : i - 1));
    setRegeneratedOutline((d) =>
      d.filter((heading) => heading.heading_id !== heading_id)
    );
  };
  const handleStartOver = () => {
    setSelectedHeadingIds([heading_id]);
    setFeedback("");
    setRegeneratedOutline(null);
    setIndex(0);
  };

  return (
    <>
      <Dialog>
        <DialogTrigger className="hover:text-fuchsia">{children}</DialogTrigger>
        <DialogContent className="max-h-screen max-w-3xl flex flex-col">
          {regeneratedOutline ? (
            <>
              <DialogHeader>
                <DialogTitle>Regenerate Outline</DialogTitle>
                <DialogDescription>
                  Accept or deny the changes.
                </DialogDescription>
              </DialogHeader>
              <ScrollArea>
                <div className="p-2 h-full">
                  {!regeneratedOutline?.length ? (
                    <div className="text-base">
                      You have no more changes.{" "}
                      <button
                        className="hover:underline text-fuchsia"
                        onClick={handleStartOver}
                      >
                        Start over
                      </button>
                      .
                    </div>
                  ) : (
                    <div className="text-sm">
                      <div className="flex gap-x-4">
                        <button
                          className="flex items-center gap-x-2 disabled:opacity-50"
                          disabled={index === 0}
                          onClick={() => setIndex((i) => i - 1)}
                        >
                          <ChevronLeftIcon className="w-4 h-4" />
                          Prev
                        </button>
                        <button
                          className="flex items-center gap-x-2 disabled:opacity-50"
                          disabled={index === regeneratedOutline.length - 1}
                          onClick={() => setIndex((i) => i + 1)}
                        >
                          Next <ChevronRightIcon className="w-4 h-4" />
                        </button>
                      </div>
                      <span className="font-semibold">
                        {
                          headingLabels[
                            headings.findIndex(
                              (h) =>
                                h.heading_id ===
                                regeneratedOutline[index].heading_id
                            )
                          ]
                        }{" "}
                        {regeneratedOutline[index].heading}
                      </span>
                      <Markdown className="*:list-disc *:list-outside *:list px-4 text-sm">
                        {regeneratedOutline[index].outline_text}
                      </Markdown>
                      <div className="flex justify-end gap-x-4">
                        <button
                          className="hover:underline"
                          onClick={() =>
                            handleDenyChange(
                              regeneratedOutline[index].heading_id
                            )
                          }
                        >
                          Deny
                        </button>
                        <button
                          className="btn-fuchsia"
                          onClick={() =>
                            handleAcceptChange({
                              heading_id: regeneratedOutline[index].heading_id,
                              outline: regeneratedOutline[index].outline_text,
                            })
                          }
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </ScrollArea>
            </>
          ) : (
            <>
              <DialogHeader>
                <DialogTitle>Regenerate Outline</DialogTitle>
                <DialogDescription>
                  Choose which headings to regenerate your outline for. Give
                  guidance or suggestions.
                </DialogDescription>
              </DialogHeader>

              <ScrollArea>
                <div className="p-2 max-h-48">
                  {headings.map((heading, i) => (
                    <div
                      className="text-sm flex items-center gap-x-2"
                      key={heading.heading_id}
                    >
                      <Checkbox
                        style={{ marginLeft: heading.depth * 10 }}
                        id={heading.heading_id}
                        onCheckedChange={(e) =>
                          handleOnCheck(e, heading.heading_id)
                        }
                        checked={selectedHeadingIds.includes(
                          heading.heading_id
                        )}
                      />
                      <label htmlFor={heading.heading_id}>
                        {headingLabels[i]} {heading.heading}
                      </label>
                    </div>
                  ))}
                </div>
              </ScrollArea>
              <Textarea
                placeholder="Enter guidance or suggestions"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
              <div className="flex justify-end">
                <button
                  className="btn-fuchsia text-sm"
                  disabled={
                    !selectedHeadingIds.length ||
                    !feedback ||
                    isRegeneratingOutline
                  }
                  onClick={regenerateOutline}
                >
                  {isRegeneratingOutline ? (
                    <span className="flex items-center gap-x-2">
                      Regenerating <LoadingSpinner />
                    </span>
                  ) : (
                    "Regenerate"
                  )}
                </button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={promptToUpgrade} onOpenChange={setPromptToUpgrade}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sorry, you are out of credits!</DialogTitle>
            <DialogDescription>
              Please upgrade to our pro tier to continue using this feature.
              Your credits will also refresh on the first of every month.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center">
            <Link
              className="bg-gradient-to-br from-fuchsia to-blue rounded-lg text-white p-2 hover:opacity-80"
              to="/settings/billing"
            >
              Upgrade to Pro
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
